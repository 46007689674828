<i18n>
{
  "en": {
    "browser_title": "Sitemap",
    "sitemap": "Sitemap",
    "main_pages": "Main Pages",
    "link_home": "Home",
    "link_actress": "Performers",
    "link_rankings": "Rankings",
    "link_join": "Sign Up",
    "video_pages": "Movies",
    "link_all_videos": "All Movies",
    "link_vip": "VIP Movies",
    "link_svip": "SVIP Movies",
    "link_annual": "Annual Movies",
    "link_categories": "Categories",
    "link_series": "Series",
    "member_services_pages": "Member Services",
    "link_upgrade": "Upgrade",
    "link_highspeed": "Highspeed Plan",
    "site_guide_pages": "Site Guide",
    "link_faq": "Help",
    "link_contact": "Contact",
    "link_sitemap": "Sitemap",
    "link_agreement": "Agreement",
    "link_privacy": "Privacy Policy",
    "language": "Language",
    "recommended_sites": "Recommended Sites",
    "site_carib": "Caribbeancom",
    "site_caribpr": "Caribbeancom Premium",
    "site_dxlive": "DX LIVE",
    "site_1pondo": "1pondo",
    "site_paco": "Pacopacomama",
    "site_10m": "10musume.com",
    "site_heyzo": "HEYZO",
    "other": "Other",
    "access_ranking": "",
    "affiliate": "",
    "chat": "",
    "business_partner": "",

    "link_freesample": "Free Sample",
    "av_pages": "Pornstars",
    "link_actors": "Actors",
    "link_ddl": "DDL",
    "link_dml": "DDL",
    "recommended_pages": "Recommended Pages"
  },
  "ja": {
    "browser_title": "サイトマップ",
    "sitemap": "サイトマップ",
    "main_pages": "メインページ",
    "link_home": "ホーム",
    "link_actress": "女のコ一覧",
    "link_rankings": "ランキング",
    "link_join": "入会案内",
    "video_pages": "動画",
    "link_all_videos": "動画一覧",
    "link_vip": "VIP動画",
    "link_svip": "超VIP動画",
    "link_annual": "年間パス動画",
    "link_categories": "カテゴリー",
    "link_series": "シリーズ",
    "member_services_pages": "会員サービス",
    "link_upgrade": "アップグレード案内",
    "link_highspeed": "ハイスピードプラン",
    "site_guide_pages": "サイトガイド",
    "link_faq": "ヘルプ",
    "link_contact": "お問い合わせ",
    "link_sitemap": "サイトマップ",
    "link_agreement": "利用規約",
    "link_privacy": "プライバシー",
    "language": "言語",
    "recommended_sites": "おすすめサイト",
    "site_carib": "カリビアンコム",
    "site_caribpr": "カリビアンコムプレミアム",
    "site_dxlive": "ライブチャット DXLIVE",
    "site_1pondo": "一本道",
    "site_paco": "パコパコママ",
    "site_10m": "天然むすめ",
    "site_heyzo": "HEYZO",
    "other": "その他",
    "access_ranking": "アクセスランキング",
    "affiliate": "アダルトアフィリエイト",
    "chat": "チャットレディのお仕事",
    "business_partner": "ビジネスパートナー",

    "link_freesample": "無料動画",
    "av_pages": "AV女優",
    "link_actors": "男優一覧 (50音順)",
    "link_ddl": "ダウンロード増量プラン",
    "link_dml": "ダウンロード増量プラン",
    "recommended_pages": "サイトガイド"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="sitemap" class="page-margin">
      <div class="block-header">
        <div class="container">
          <h1>{{ $t('sitemap') }}</h1>
        </div>
      </div>
      <div class="container">
        <div class="section">
          <h2 class="is-secondary">{{ $t('main_pages') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <router-link to="/">{{ $t('link_home') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/ranking/">{{ $t('link_rankings') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div v-if="!isSiteMember" class="grid-item">
              <router-link to="/join/">{{ $t('link_join') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
          </div>
        </div>

        <hr>
        <div class="section">
          <h2 class="is-secondary">{{ $t('video_pages') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <router-link to="/list/?page=1&o=newest">{{ $t('link_all_videos') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item" v-if="!newXPEligible">
              <router-link to="/search/?page=1&size=20&c=50000">{{ $t('link_vip') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item" v-if="!newXPEligible">
              <router-link to="/search/?page=1&size=20&c=50001">{{ $t('link_svip') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item" v-if="!newXPEligible">
              <router-link to="/search/?page=1&size=20&c=50003">{{ $t('link_annual') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/category/">{{ $t('link_categories') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/series/">{{ $t('link_series') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
          </div>
        </div>

        <hr v-if="!newXPEligible">
        <div class="section" v-if="!newXPEligible">
          <h2 class="is-secondary">{{ $t('member_services_pages') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <router-link to="/upgrade/">{{ $t('link_upgrade') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/highspeed/">{{ $t('link_highspeed') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
          </div>
        </div>

        <hr>
        <div class="section">
          <h2 class="is-secondary">{{ $t('site_guide_pages') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <router-link to="/faq/">{{ $t('link_faq') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/contact/">{{ $t('link_contact') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/sitemap/">{{ $t('link_sitemap') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/agreement/">{{ $t('link_agreement') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
            <div class="grid-item">
              <router-link to="/privacy/">{{ $t('link_privacy') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
            </div>
          </div>
        </div>

        <hr>
        <div class="section">
          <h2 class="is-secondary">{{ $t('language') }}</h2>
          <div class="grid is-list">
            <div class="grid-item" v-if="locale == 'ja'">
              <a href="https://en.pacopacomama.com/">English<i class="iconf-keyboard-arrow-right is-right"></i></a>
            </div>
            <div class="grid-item" v-if="locale == 'en'">
              <a href="https://www.pacopacomama.com/">日本語<i class="iconf-keyboard-arrow-right is-right"></i></a>
            </div>
          </div>
        </div>

        <hr>
        <div class="section">
          <h2 class="is-secondary">{{ $t('recommended_sites') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <a href="https://bit.ly/3YNngGL" target="_blank" rel="noopener">{{ $t('site_carib') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener">{{ $t('site_caribpr') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://bit.ly/4dqfMNR" target="_blank" rel="noopener">{{ $t('site_1pondo') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://bit.ly/4dLkRk7" target="_blank" rel="noopener">{{ $t('site_10m') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener">{{ $t('site_dxlive') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
          </div>
        </div>

        <hr v-if="locale == 'ja'">
        <div class="section" v-if="locale == 'ja'">
          <h2 class="is-secondary">{{ $t('other') }}</h2>
          <div class="grid is-list">
            <div class="grid-item">
              <a href="http://www.adultmango.com/cgi-bin/ranklink.cgi?id=paco2">{{ $t('access_ranking') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://www.affiliate-dti.com/">{{ $t('affiliate') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="http://www.dxlive.com/job/">{{ $t('chat') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
            <div class="grid-item">
              <a href="https://www.d2pass.com/form/business_partner/">{{ $t('business_partner') }}<i class="iconf-ex-link is-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /#sitemap -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_sitemap.scss';
</style>
